import { store } from '../store';

class Navigation {
    constructor(args) {
        args = args || {};
        this.homeButton = document.querySelector('.navigation-mobile .btn-home');
        this.homeButtonMobile = document.querySelector('.btn-home');
        this.scroll = args.scroll || null;
        this.menuOpen = false;
        this.subNav = document.querySelector('.navigation-mobile .menu-items');
        this.nav = document.querySelector('.navigation');
        this.buttonMobile = document.querySelector('.btn-menu-mobile');
        this.menuItems = document.querySelectorAll('.menu-items button');
        this.menuItemsMobile = document.querySelectorAll('.navigation-mobile .menu-items');
        this.submenuItems = document.querySelectorAll('.menu-items button ul li a');
        this.menu = document.querySelector('.btn-menu');
        this.closeButton = document.querySelector('.btn-close');
        this.closeButtonMobile = document.querySelector('.navigation-mobile .btn-close');
        this.activeId = null;
        this.galleryMenuActive = false;   
        this.navigationVisible = true;
        this.gallery4xGrid = document.querySelector('.btn-gallery4x');
        this.gallery2xGrid = document.querySelector('.btn-gallery2x');
        this.galleryFull = document.querySelector('.btn-galleryFull');
        this.navigationBackground = document.querySelector('.navigation__background');
        this.navigationBackgroundMobile = document.querySelector('.navigation-mobile .navigation__background');
        this.setupListeners();
    }

    attachGallery(gallery) {
        this.gallery = gallery;

        //gallery close button
        this.closeButton.addEventListener('click', (e) => {
            this.gallery.closeGallery();
        });

        if(this.closeButtonMobile) { 
            this.closeButtonMobile.addEventListener('click', (e) => {
                this.gallery.closeGallery();
            });
        }
    }

    setupListeners() {

        //mobile button

        if (this.buttonMobile) {
            this.buttonMobile.addEventListener('click', (e) => {    
            // e.preventDefault();
                this.mobileOpenNav();
            });
        }

        // menu button
        this.menu.addEventListener('click', (e) => {
            e.preventDefault();
            store.dispatch({ type: "TOGGLE_NAVIGATION_VISABILITY", payload: !this.navigationVisible });
        });

        // home button
        this.homeButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.subNav.classList.remove('mobileShow');
            this.closeAlreadyActiveMenuItems();
            this.navigationBackground.classList.add('closed');
            this.navigationBackgroundMobile.classList.add('closed');
            return true;
        });

        this.homeButtonMobile.addEventListener('click', (e) => {
            e.preventDefault();
            this.subNav.classList.remove('mobileShow');
            this.closeAlreadyActiveMenuItems();
            this.navigationBackground.classList.add('closed');
            this.navigationBackgroundMobile.classList.add('closed');
            return true;
        });

        // main navigation
        this.menuItems.forEach((item) => {
            item.addEventListener('mouseenter', (e) => {
                if (e.target.classList.contains('nosub')) {
                    this.closeAlreadyActiveMenuItems();
                    this.navigationBackground.classList.add('closed');
                    return true;
                } else {
                    e.preventDefault();
                    this.nav.classList.add('noblend');
                    this.closeAlreadyActiveMenuItems();
                    this.navigationBackground.classList.remove('closed');
                    this.menuOpen = true;
                    this.activeId = e.target.id;
                    e.target.classList.add('active');
                    if (e.target.parentNode) {
                        // e.target.parentNode.classList.toggle('active');
                    }
                }
            });

            this.navigationBackground.addEventListener('click', (e) => {
                if (this.menuOpen){
                    this.closeAlreadyActiveMenuItems();
                    this.navigationBackground.classList.add('closed');
                    
                    setTimeout(() => {
                        this.nav.classList.remove('noblend');
                    }, 1000);
                }
            });
        });

        // submenu navigation
        this.submenuItems.forEach((item) => {
            item.addEventListener('click', (e) => {

                this.subNav.classList.remove('mobileShow');
                this.navigationBackgroundMobile.classList.add('closed');
                if (e.target.parentNode.parentNode.parentNode) {
                    e.target.parentNode.parentNode.parentNode.classList.remove('active');
                }
            });
        });

        // gallery buttons
        this.gallery4xGrid.addEventListener('click', () => {
            this.gallery.setGalleryMode('layout4x');
        });

        this.gallery2xGrid.addEventListener('click', () => {
            this.gallery.setGalleryMode('layout2x');
        });

        this.galleryFull.addEventListener('click', () => {
            this.gallery.setGalleryMode('layoutFull');
            this.hideGalleryMenu();
        });
    }

    closeAlreadyActiveMenuItems() {
        this.menuItems.forEach((item) => {
            item.classList.remove('active');
        });
    }

    toggleGalleryMenu(state) {
        this.galleryMenuActive = state;

        if (state) {
            document.querySelector('.navigation .controls').classList.add('active');
        } else {   
            document.querySelector('.navigation .controls').classList.remove('active');
        }
    }

    hideGalleryMenu() {
        document.querySelector('.navigation .controls').classList.remove('active');
    }

    showGalleryMenu() {
        document.querySelector('.navigation .controls').classList.add('active');
    }

     toggleVisibility(state) {
        if (state) {
            this.nav.classList.remove('hidden');
            //this.subNav.classList.remove('mobileShow');
            this.navigationVisible = true;
        } else {
            this.nav.classList.add('hidden');
            //this.subNav.classList.add('mobileShow');
            this.navigationVisible = false;
        }
     }

     onChange(state) {
        this.toggleVisibility(state.navigationVisible);
    }

    reset() {
        this.hideGalleryMenu();
        this.navigationBackground.classList.add('closed');
        this.navigationBackgroundMobile.classList.add('closed');
    }

    hideMainButton() {
        this.menu.classList.add('hidden');
        this.buttonMobile.classList.add('hidden');
        this.closeButton.classList.add('active');
        this.closeButtonMobile.classList.add('active');
    }

    showMainButton() {
        this.menu.classList.remove('hidden');
        this.buttonMobile.classList.remove('hidden');
        this.closeButton.classList.remove('active');
        this.closeButtonMobile.classList.remove('active');
    }

    mobileOpenNav() {
        this.navigationBackgroundMobile.classList.remove('closed');
        this.subNav.classList.add('mobileShow');
    }

}

export default Navigation;