import Draggable from 'draggable';
import gsap from 'gsap';
import mobile from 'is-mobile';

class SplitScreen {
    constructor(args) {
        args = args || {}
        this.scroll = args.scroll || null;
        this.container = args.container;
        this.leftPanel = args.leftPanel;
        this.rightPanel = args.rightPanel;
        this.dragHandle = args.dragHandle;
        this.offsetWidth = this.dragHandle && this.dragHandle.offsetWidth || 0;
        this.minWidth = args.minWidth || 500;
        this.padding = args.padding || 0;

        if (!this.dragHandle) return;

        this.setupDrggable();
        this.setGalleryHeight(true);
    }

    setGalleryHeight() {
        if (mobile()) return;
    
        setTimeout(() => {
            const gallery = document.querySelector('.gallery');
            const galleryIndex = document.querySelector('.gallery__index');
            const galleryInner = document.querySelector('.gallery__inner');

            if (gallery) {
                const newHeight = galleryIndex.offsetHeight < window.innerHeight ? window.innerHeight + 'px' : galleryIndex.offsetHeight + 'px';

                galleryInner.style.height = newHeight;
                gallery.style.height = newHeight;

                this.scroll.update();
            }
        }, 500);
    }
    
    setupDrggable() {
        if (mobile()) return;

        var options = {
            limit: { x: [0, window.innerWidth - this.offsetWidth], y: [0, 0] },
            onDrag: () => {
                this.rightPanel.style.width = (window.innerWidth - this.dragHandle.offsetLeft)  + 'px';
                this.leftPanel.style.width = (window.innerWidth - this.rightPanel.offsetWidth ) +  'px';
            },
            onDragEnd: (e) => {
                this.snapToPosition();
            }
        };
        new Draggable (this.dragHandle, options);
    }

    snapToPosition() {
        if (mobile()) return;

        const width = parseInt(this.rightPanel.style.width.replace('px', ''));
        
        if (width < window.innerWidth / 3) {
            gsap.to(this.rightPanel, {
                width: this.minWidth,
                duration: 0.3
            });

            gsap.to(this.dragHandle, {
                left: (window.innerWidth - this.minWidth),
                duration: 0.3,
                onComplete: () => {
                    this.adjustLeftPanel();
                }
            });
        }

        if (width > window.innerWidth / 3) {
            gsap.to(this.rightPanel, {
                width: window.innerWidth / 2,
                duration: 0.3
            });

            gsap.to(this.dragHandle, {
                left: window.innerWidth / 2,
                duration: 0.3,
                onComplete: () => {
                    this.adjustLeftPanel();
                }
            });
        }

        if (width > window.innerWidth / 2) {
            gsap.to(this.rightPanel, {
                width: window.innerWidth * 0.65,
                duration: 0.3
            });

            gsap.to(this.dragHandle, {
                left: window.innerWidth * 0.35,
                duration: 0.3,
                onComplete: () => {
                    this.adjustLeftPanel();
                }
            });
        }

    }

    adjustLeftPanel() {
        if (mobile()) return;
        this.leftPanel.style.width = window.innerWidth - (this.rightPanel.clientWidth + this.padding) + 'px';
        this.setGalleryHeight();
        this.scroll.scrollTo('.gallery');
    }

    update() {

        if (mobile()) return;
        if (!this.leftPanel) return;

        gsap.to(this.dragHandle, {
            left: (window.innerWidth / 2),
            duration: 0.1,
            onComplete: () => {
                this.rightPanel && this.dragHandle ? this.rightPanel.style.width = (window.innerWidth - this.dragHandle.offsetLeft)  + 'px' : null;
                this.leftPanel ? this.leftPanel.style.width = (window.innerWidth - this.rightPanel.offsetWidth ) +  'px' : null;
                this.dragHandle ? this.dragHandle.style.height = window.innerHeight + 'px' : null;
                this.setGalleryHeight();
            }
        });
    }
}

export default SplitScreen;