import barba from '@barba/core';
import LocomotiveScroll from 'locomotive-scroll';
import SplitScreen from './SplitScreen';
import Navigation from './Navigation';
import Gallery from './Gallery';
import { store } from '../store';
import gsap from 'gsap';
import imagesLoaded from 'imagesloaded';
const PRIMARY_EMAIL = 'studio@andrewzuckerman.com'

class Site {
    constructor() {
        this.setupRouting.bind(this);
        this.setupRouting();
        this.setupWindowEvents();
        this.body = document.querySelector('body');
        this.leftPanel = document.querySelector('.gallery__leftPanel');
        this.nav = document.querySelector('.navigation');
        this.homeSlide = document.querySelector('.homeslider');

        if (this.homeSlide) {
          this.homeSlide.style.height = window.innerHeight + 'px';
        }

        this.scroll = new LocomotiveScroll({
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          smartphone: { smooth: false }, 
          tablet: { smooth: true }
        });

        window.addEventListener('blur', () => {
          this.scroll.stop();
        });

        window.addEventListener('focus', () => {
          this.scroll.start();
        });

        this.initPage();
    }

    setupRouting() {
      const _self = this;

      barba.init({
          schema: {
              prefix: 'data-site',
              wrapper: 'app'
          },
          transitions: [{
            name: 'slide',
            leave(data) {
              document.querySelector('.site-wrapper').classList.add('is-leaving');
              
              return gsap.to('.preloader', {
                right: 0
              });
            },
            afterLeave(data) {
     
              return gsap.to('.preloader', {
                left: '100vw',
                delay: .5
              });
            }
          }],
          views: [{
            namespace: 'site',
            beforeLeave() {
              _self.gallery && _self.gallery.closeGallery();
              _self.navigation && _self.navigation.hideGalleryMenu();
              _self.nav.style.pointerEvents = 'none';
              _self.destroyPage();
            },
            afterEnter() {
              _self.scroll.update();
              _self.scroll.scrollTo("top", { duration: 0, disableLerp: true});
              _self.navigation.reset();
              document.querySelector('.site-wrapper').classList.remove('is-leaving');
              _self.nav.style.pointerEvents = 'all';
            }
          }]
      });

      barba.hooks.before(() => {
        document.querySelector('.site-wrapper').classList.add('.is-leaving');
        this.gallery.destroy();
        this.scroll.scrollTo("top", { duration: 10});
      });

      barba.hooks.after(() => {
        this.initPage();
        this.scroll.update();
        this.gallery.update();
        document.querySelector('.site-wrapper').classList.remove('.is-leaving');

        this.body.scrollTo(0,1);
      });
    }

    destroyPage() {
      delete this.splitScreen;
      this.gallery.destroy();
      delete this.gallery;
    }

    replaceEmail() {
      const emailHolders = document.querySelectorAll('.__cf_email__');

      emailHolders.forEach((email) => {
        const parentNode = email.parentNode;
        parentNode.setAttribute("href", `mailto:${PRIMARY_EMAIL}`);
        email.innerHTML = PRIMARY_EMAIL;
      });
    }

    initPage() {
      const _self = this;

      this.leftPanel = document.querySelector('.gallery__leftPanel');
      this.nav = document.querySelector('.navigation');
      this.homeSlide = document.querySelector('.homeslider');

      this.replaceEmail();
      
      if (this.homeSlide) {
        this.homeSlide.style.height = window.innerHeight + 'px';
      }
      
      imagesLoaded( document.querySelector('body'), function( instance ) {
        _self.resizeHandler();
      });

      setTimeout(() => {
        document.querySelector('.preloader').style.left = 0;
        document.querySelector('.preloader').style.right = '100vw';
      }, 1000);      

      this.navigation = new Navigation({
        scroll: this.scroll
      });

      this.scroll.on('scroll', (args) => {
        if (args.scroll.y > 50) {
          document.querySelector('.btn-menu').classList.remove('mask');
          store.dispatch({ type: "TOGGLE_NAVIGATION_VISABILITY", payload: false });
        } else if (args.scroll.y < 50) {
          document.querySelector('.btn-menu').classList.add('mask');
          store.dispatch({ type: "TOGGLE_NAVIGATION_VISABILITY", payload: true });
        }
      });

        this.splitScreen = new SplitScreen({
            scroll: this.scroll,
            dragHandle: document.querySelector('.gallery__drag'),
            leftPanel: this.leftPanel,
            rightPanel: document.querySelector('.gallery__rightPanel'),
        });

      this.gallery = new Gallery({
        navigation: this.navigation,
        scroll: this.scroll,
        update: () => {
          this.splitScreen.setGalleryHeight.bind(this.splitScreen);
          this.gallery.setTextOverflowHeight();
          this.splitScreen.setGalleryHeight();
        }
      });

      this.navigation.attachGallery(this.gallery);

      store.subscribe(() => {
        const state = store.getState();
        this.navigation && this.navigation.onChange(state.app);
      });

      this.scroll.on('call', (func, args, obj) => {
        if (func === "toggleGallery") {
          const galleryOpen = document.querySelector('.lg-show');
          args === "enter" && !galleryOpen ? this.navigation.showGalleryMenu() : this.navigation.hideGalleryMenu();
        } 
      });

    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
      if (this.scroll) this.scroll.update();
      if (this.gallery) this.gallery.update();
      if(this.splitScreen) this.splitScreen.update();

    }
}

export default Site;