const initialState = {
    galleryMode: '4x',
    navigationOpen: false,
    navigationVisible: true,
    users: [],
    posts: [],
    comments: [],
};

export const appReducer = (state = initialState, action) => {
    if (action.type === "SET_GALLERY_MODE") {
        return {...state, galleryMode: action.payload};
    }

    if (action.type === "TOGGLE_NAVIGATION") {
        return {...state, navigationOpen: action.payload};
    }

    if (action.type === "TOGGLE_NAVIGATION_VISABILITY") {
        return {...state, navigationVisible: action.payload};
    }

    return state;
};