import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';
import lgVimeoThumbnail from 'lightgallery/plugins/vimeoThumbnail';


class Gallery {
    constructor(args) {
        args = args || {};
        this.nocontrols = document.querySelectorAll('.nocontrol');
        this.scroll = args.scroll || null;
        this.navigation = args.navigation || null;
        this.update = args.update || null;
        this.galleryMode = 'layout2x';
        this.gallery = document.querySelector('.gallery__index');
        this.customScroll = document.querySelector('.custom-scrollbar');
        this.leftSide = document.querySelector('.gallery__leftPanel');
        this.galleryItems = document.querySelectorAll('.gallery__item');
        this.imageItems = document.querySelectorAll('.image-item');

        this.lg = document.getElementById('sidegallery');

        this.galleryItems.forEach((item) => {
            item.addEventListener('click', this.showPreview.bind(this));
        });

        if (this.customScroll) {
            this.setTextOverflowHeight();

            // this.customScroll.addEventListener('mouseover', () => {
            //    // this.scroll.stop();
            // });

            // this.customScroll.addEventListener('mouseout', () => {
            //    //this.scroll.start();
            // });
        }

        if (this.lg) {
            this.lg.addEventListener('lgBeforeOpen', (e) => {
                document.querySelector('.lg-content').style.top = 0;
                this.navigation.reset();
                this.navigation.hideGalleryMenu();
                this.navigation.hideMainButton();
            });

            this.lg.addEventListener('lgAfterOpen', (e) => {
               setTimeout(() => {
                this.closePreview();
               }, 1000);
            });

            this.lg.addEventListener('lgBeforeClose', () => {
                if (!this.nocontrols) this.navigation.showGalleryMenu();
                this.navigation.showMainButton();
                document.documentElement.style.position = "relative";

                setTimeout(() => {  
                    document.documentElement.style.position = "fixed";
                }, 400);

            });

            this.galleryInstance = lightGallery(this.lg, {
                licenseKey: 'D2E41CD6-D0DB-4ABE-935D-870B6624318A',
                plugins: [lgVideo, lgVimeoThumbnail],
                speed: 500,
                mode: 'lg-fade',
                selector: '.gallery__item',
                dynamic: true,
                dynamicEl: [...this.imageItems].map((item, i) => {
                    return { 
                        src: item.dataset['src'],
                        id: i,
                    }
                }),
                vimeoPlayerParams: {
                    byline : 0,
                    portrait : 0,
                    color : 'A90707'     
                },
                thumbnails:false, 
                controls: true, 
                loadVimeoThumbnail: true, 
                vimeoThumbSize: "default"
            });
        }

        this.closeGallery.bind(this);
    }

    setTextOverflowHeight() {
        if (!this.customScroll) return;
        this.customScroll.style.height = (this.leftSide.getBoundingClientRect().height - 280) + 'px';
    }

    setGalleryMode(mode) {
        if (mode === "layoutFull") {
            this.galleryInstance.openGallery();
        } else {
            this.gallery.classList.add('fadeOut');
            setTimeout(() => {
                this.gallery.className = 'gallery__index ' + mode
            }, 200);

            setTimeout(() => {
                this.gallery.classList.remove('fadeOut');
                this.scroll.scrollTo('.gallery');
            }, 300);
        }

        this.update();
    }

    onChange(state) {

    }

    destroy() {
        this.galleryItems.forEach((item) => {
            item.removeEventListener('click', this.showPreview);
        });
        this.galleryInstance.destroy();
    }

    closeGallery() {
        this.navigation && this.navigation.hideGalleryMenu();
        this.galleryInstance && this.galleryInstance.closeGallery();
    }

    showPreview (e) {


        if (window.innerWidth < 600) {
            this.galleryInstance.openGallery(parseInt(e.currentTarget.dataset.id));
            return;
        }

        if (e.currentTarget.dataset.video || e.currentTarget.dataset.book || e.currentTarget.dataset.exhibition) {
            this.galleryInstance.openGallery(parseInt(e.currentTarget.dataset.id));
            return;
        }
     
        const runAnimation = async (el) => {
            const currentTarget = el.currentTarget;
            await this.closePreview();

            const galleryType = currentTarget.dataset.gallerytype;
            
            const previewContainer = document.createElement("div");
            previewContainer.classList.add('preview-container');    
            previewContainer.style.backgroundImage = `url(${currentTarget.dataset.preview})`;
            currentTarget.before(previewContainer);
            currentTarget.classList.add('hideGalleryItem');

            if (galleryType === 'Square_1_1') {
                previewContainer.classList.add('square');
            }

            previewContainer.addEventListener('click', (e) => {
                this.galleryInstance.openGallery(parseInt(currentTarget.dataset.id));
            });
            
            setTimeout(() => {
                previewContainer.classList.add('in');
            }, 100);
            
            setTimeout(() => {
                this.update();
            }, 1000);

            this.scroll.scrollTo('.preview-container', { offset: -60});
        };

        runAnimation(e);
    }

    closePreview() {

        const ani = new Promise((resolve, reject) => {
            const currentlyHiddenItems = document.querySelectorAll('.hideGalleryItem');
            const currentlyOpenPanels = document.querySelectorAll('.preview-container');

            if (!currentlyOpenPanels.length) {
                resolve();
                return;
            }

            currentlyHiddenItems.forEach((item) => {
                item.classList.remove('hideGalleryItem');
            });

            currentlyOpenPanels.forEach((item) => {
                item.classList.remove('in');
                item.remove();
                resolve();
            });
            
        });

        return ani;
    }
}

export default Gallery;